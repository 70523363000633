/** @jsxImportSource @emotion/react */
import { fadeIn, fadeOut } from '../styles/transitions'
import { hostLink, slotRangesOptionsStyle } from '../styles/game-list'
import normaliseName from '../helpers/normalise-game-name'
import { imageInfo, tinyDisplay } from './styles'

import AffiliateButton from '../buttons/affiliate-click'
import { useAppSelector } from '../../app/hooks/typedHooks'
import { GamesType } from '../host-row'

export type GameListProps = {
  server: any
  aOrB: boolean
}

export type gameProps = {
  affiliate_details: any
  game: string
  platforms: string[]
  affiliate_link: string
}

export const GameList: React.FC<GameListProps> = ({ server, aOrB }) => {
  const { currentGame, currentPlatform, currentHost } = useAppSelector(
    (state) => state.currentGameHost,
  )

  const { affiliate_link, trust_pilot_slug, server_name, url } = server

  if (currentHost === 'all' || !server.game_array.length) return null

  const allGamesSelected = currentGame === 'all'

  if (currentGame !== 'all' && !(server.game_array.indexOf(currentGame) > -1)) {
    return (
      <p css={{ fontSize: '2em' }}>
        The Host does not appear to offer this game
      </p>
    )
  }

  return (
    <div css={slotRangesOptionsStyle} className="slot-range-options">
      <h4 style={{ textAlign: 'center' }}>
        TAP OR CLICK GAME IMAGE TO GO TO WEBSITE
      </h4>
      <div
        css={tinyDisplay}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div
          css={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
        >
          {server.games.map((game: GamesType) => {
            const correctPlatform =
              currentPlatform === 'all' ||
              game.platforms.includes(currentPlatform)
            const correctGame =
              allGamesSelected || game.name_raw === currentGame
            const show = correctPlatform && correctGame ? fadeIn : fadeOut
            const imagePath = 'images/bannerImages'
            const affiliate_details = game.affiliate_game_link
            const affiliateLogic = affiliate_details || affiliate_link

            return (
              <div
                key={game.name_raw}
                css={[show, imageInfo]}
                className="slot-ranges"
              >
                <AffiliateButton
                  altText={`${game.name_nice} clickable image`}
                  passedStyles={hostLink}
                  serverName={server_name}
                  url={url}
                  affiliateLogic={affiliateLogic}
                  aOrB={aOrB}
                  currentGame={currentGame}
                  imagePath={imagePath}
                  imageName={game.name_raw}
                  loading="lazy"
                />
                <p>{game.name_nice}</p>
              </div>
            )
          })}
        </div>

        <AffiliateButton
          altText={`${server_name} clickable image`}
          serverName={server_name}
          url={url}
          affiliateLogic={affiliate_link}
          aOrB={aOrB}
          currentGame={currentGame}
          imagePath={'images/logos'}
          imageName={trust_pilot_slug}
          loading="lazy"
          passedStyles={hostLink}
        />
      </div>
    </div>
  )
}

export default GameList

// //                   <picture>
//                     {/* move this somewhere else */}
//                     {/* /* <a href={VideoLinks[game.game].gamePurchaseLink}> */}
//                     <PictureSet imagePath={imagePath} imageName={game.game} />
//                     <img
//                       css={gameListImageStyle}
//                       loading="lazy"
//                       alt={game.game}
//                       className="game-image"
//                       // src={`/${imagePath}/normal/png/${game.game}.png`}
//                     />
//                   </picture>
