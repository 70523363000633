/** @jsxImportSource @emotion/react */
import countryArray from '../helpers/countryArray'
import PictureSet from '../helpers/picture-set'
import { ServerDataType } from '../host-row'
import {
  flagBannerImageStyling,
  displayGrid,
  bannerImagesHoverStyle,
  inactiveBannerImagesHoverStyle,
} from './styles'
import { useAppSelector } from '../../app/hooks/typedHooks'
import { FlagBannerProps } from '../../types/flagBannerProps'

const imagePath = 'images/flags'

const flagBanner: React.FC<FlagBannerProps> = (props) => {
  const { handleCountryChange } = props

  const { currentCountry, currentHost } = useAppSelector(
    (state) => state.currentGameHost,
  )

  const { serverData = [] } = useAppSelector(
    (state) => state.gameServerData || {},
  )

  const hostCountryArray =
    currentHost === 'all'
      ? false
      : serverData?.find(
          (server: ServerDataType) => server.server_name === currentHost,
        )?.easy_locations
  const newCountryArray = !hostCountryArray ? countryArray : hostCountryArray

  return (
    <div
      id="banner-anchor"
      className="banner-images"
      css={[displayGrid, { margin: '1em auto 1em auto' }]}
    >
      {countryArray.map((country: string) => {
        const isCurrentCountry = country === currentCountry
        const isAll = currentCountry === 'all'
        const transform =
          isCurrentCountry && !isAll ? 'scale(1.25)' : 'scale(1)'

        let isSelectable = newCountryArray.includes(country)
        const opacity = isAll || isCurrentCountry ? '1' : '0.4'
        const imageStyle = isCurrentCountry
          ? bannerImagesHoverStyle
          : inactiveBannerImagesHoverStyle

        if (isSelectable) {
          return (
            <div
              onClick={handleCountryChange}
              data-country={country}
              key={country}
              className="flag-image-container"
            >
              <div>
                <picture>
                  <PictureSet imagePath={imagePath} imageName={country} />

                  <img
                    loading="lazy"
                    css={[
                      flagBannerImageStyling,
                      imageStyle,
                      { opacity },
                      { transform },
                    ]}
                    key={country}
                    src={`/${imagePath}/normal/png/${country}.png`}
                    alt={`Country flag ${country}`}
                  />
                </picture>
              </div>
            </div>
          )
        }
      })}
    </div>
  )
}

export default flagBanner
