import { css } from '@emotion/react'
import { smallScreen } from '../styles/global'

export const flexxy = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4em;
`

export const bannerContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const bannerGameFilter = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2em;
  font-size: 1.5em;
`

export const linkStyling = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const gameBannerImagesStyle = css`
  transition: all 0.2s ease-in-out;
  margin-left: 1%;
  margin-right: 1%;
  position: relative;
  z-index: 1;
  transform: scale(1);
  width: 150px;
  height: 86px;

  &:hover {
    position: relative;
    transform: scale(1.25);
    z-index: 2;
    opacity: 1;
    overflow: visible;
  }

  ${smallScreen} {
    width: 150px;
    height: 86px;
  }
`

export const inActiveGame = css`
  transition: all 0.2s ease-in-out;
  z-index: 0;
  margin-left: 1%;
  margin-right: 1%;
  width: 98%;
  opacity: 0.2;
  object-fit: contain;

  &:hover {
    position: relative;
    z-index: 0;
    opacity: 0.2;
    overflow: visible;
  }
`

export const displayGrid = css`
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  justify-content: center;
  cursor: pointer;
  align-content: center;
`

export const highlighted = css`
  padding: 0.8em;
  position: relative;
  opacity: 1;
  z-index: 1;
  transform: scale(1.25);
`

export const notHighlighted = css`
  padding: 0.8em;
  position: relative;
  opacity: 0.4;
  z-index: 0;
  transform: scale(0.5);
`

export const notInList = css`
  opacity: 0.2;

  &:hover {
    cursor: default;
    opacity: 0.2;
  }
`
