import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { GamesType, ServerDataType } from '../../../src/shared/host-row'

export type BannerGamesType = {
  name_raw: string
  name_nice: string
  purchase_link: string
  youtube_embed_code: string
  easy_locations: string[]
  categories?: string[]
}

export type GameServerDataState = {
  serverData: ServerDataType[]
  sortedServerData: ServerDataType[]
  uniqueHosts: string[]
  uniqueGames: string[]
  games: BannerGamesType[]
  loading: boolean
}

export const gameServerDataInitialState: GameServerDataState = {
  serverData: [],
  sortedServerData: [],
  uniqueHosts: [],
  games: [],
  uniqueGames: [],
  loading: false,
}

// Async thunk for fetching data
export const fetchData = createAsyncThunk(
  'gameServerData/fetchData',
  async () => {
    const response = await fetch('/api/data')
    const data: {
      serverData: ServerDataType[]
      uniqueHosts: string[]
      games: BannerGamesType[]
      uniqueGames: string[]
      loading: boolean
      sortedServerData: ServerDataType[]
    } = await response.json()

    return data
  },
)

// Slice
const gameServerDataSlice = createSlice({
  name: 'gameServerData',
  initialState: gameServerDataInitialState,
  reducers: {
    setSortedServerData: (state, action: PayloadAction<ServerDataType[]>) => {
      state.serverData = action.payload || []
    },

    setServerData: (state, action: PayloadAction<ServerDataType[]>) => {
      state.serverData = action.payload || []
    },

    setUniqueHosts: (state, action: PayloadAction<string[]>) => {
      state.uniqueHosts = action.payload || []
    },
    setGames: (state, action: PayloadAction<BannerGamesType[]>) => {
      state.games = action.payload || []
    },
    setUniqueGames: (state, action: PayloadAction<string[]>) => {
      state.uniqueGames = action.payload || []
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false
        state.serverData = action.payload.serverData || []
        state.sortedServerData = action.payload.serverData || []
        state.uniqueHosts = action.payload.uniqueHosts || []
        state.uniqueGames = action.payload.uniqueGames || []
        state.games = action.payload.games || []
      })
      .addCase(fetchData.rejected, (state) => {
        state.loading = false
      })
  },
})

export const {
  setSortedServerData,
  setServerData,
  setUniqueHosts,
  setUniqueGames,
  setGames,
  setLoading,
} = gameServerDataSlice.actions

export default gameServerDataSlice.reducer
