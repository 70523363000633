/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import PictureSet from '../helpers/picture-set'
import {
  displayGrid,
  gameBannerImagesStyle,
  highlighted,
  inActiveGame,
  notHighlighted,
  notInList,
  flexxy,
  linkStyling,
  bannerContainerStyle,
  bannerGameFilter,
} from './styles'
import { ServerDataType } from '../host-row'
import { useAppSelector } from '../../app/hooks/typedHooks'
import { isEmpty } from 'lodash'
import { BannerGamesType } from '../../app/slices/gameServerDataSlice'

const imagePath = 'images/bannerImages'

const getTransform = (isCurrentGame: boolean, isAll: boolean) => {
  return isCurrentGame && !isAll ? 'scale(1.25)' : 'scale(1)'
}

const getCurrentStyling = (
  isCurrentGame: boolean,
  isAll: boolean,
  currentGame: string,
) => {
  return (isCurrentGame && !isAll) || currentGame === 'all'
    ? highlighted
    : notHighlighted
}

const getOverStyling = (active: boolean) => {
  return active ? {} : notInList
}

const getImageStyle = (active: boolean) => {
  return active ? gameBannerImagesStyle : inActiveGame
}

const gameName = (game: string, currentGame: string) => {
  return game !== currentGame ? game : 'all'
}

export const topGames = [
  '7-days-to-die',
  'ark-survival-evolved',
  'arma-reforger',
  'conan-exiles',
  'CSGO',
  'dayz',
  'enshrouded',
  'factorio',
  'palworld',
  'project-zomboid',
  'rust',
  'soulmask',
  'squad',
  'satisfactory',
  'v-rising',
  'valheim',
]

type GameBannersProps = {}

export const GameBanners: React.FC<GameBannersProps> = ({}) => {
  const [gameFilter, setGameFilter] = useState('') // New filter state
  const { currentGame, currentHost, currentCountry } = useAppSelector(
    (state) => state.currentGameHost,
  )
  const { serverData, uniqueGames, games } = useAppSelector(
    (state) => state.gameServerData,
  )

  const topGamesObject = () =>
    games.filter((game) => topGames.includes(game.name_raw)).filter(Boolean)

  const getServerGames = (): BannerGamesType[] => {
    if (gameFilter !== '') {
      return games.filter(
        (game) =>
          game.name_nice.toLowerCase().includes(gameFilter.toLowerCase()) ||
          game.name_raw.toLowerCase().includes(gameFilter.toLowerCase()),
      )
    }
    if (currentHost === 'all' && currentGame === 'all') {
      return topGamesObject()
    } else if (currentHost !== 'all' && currentGame !== 'all') {
      const currentGameObject = games.find(
        (game) => game.name_raw === currentGame,
      )
      return [currentGameObject as BannerGamesType]
    } else if (currentHost !== 'all' && currentGame === 'all') {
      const currentServer = serverData.find((server) => {
        return server.server_name === decodeURI(currentHost)
      })
      const gameArray = currentServer?.game_array
      return gameArray?.map((game_array_name_raw) => {
        return games.find((game) => game.name_raw === game_array_name_raw)
      }) as BannerGamesType[]
    } else if (currentHost !== 'all' && currentGame !== 'all') {
      const currentServer = serverData.find((server) => {
        return server.server_name === decodeURI(currentHost)
      })
      const gameArray = currentServer?.game_array
      return gameArray?.map((game_array_name_raw) => {
        return games.find(
          (game) => game.name_raw === game_array_name_raw,
        ) as BannerGamesType
      }) as BannerGamesType[]
    } else return []
  }

  let currentServerGames: string[] | undefined
  if (currentHost === 'all' || isEmpty(serverData)) {
    currentServerGames = uniqueGames
  } else {
    currentServerGames = serverData.find(
      (server: ServerDataType) => server.server_name === currentHost,
    )?.game_array
  }

  // Filter games based on user input
  const filteredGames = () => {
    if (currentGame !== 'all') {
      if (currentCountry !== 'all') {
        return getServerGames().filter((game) =>
          game.easy_locations.includes(currentCountry),
        )
      }
      return getServerGames()
    }

    return getServerGames().filter(
      (game) =>
        game.name_nice.toLowerCase().includes(gameFilter.toLowerCase()) ||
        game.name_raw.toLowerCase().includes(gameFilter.toLowerCase()),
    )
  }

  let filteredGamesList = filteredGames()

  const noGames = () => {
    return (
      <div css={bannerContainerStyle} className="no-games-available">
        <p style={{ fontSize: '2em' }}>
          No Games, with those letters, in that order 🙂
        </p>
      </div>
    )
  }
  // TODO When filtering games with a server selected it's not only filtering the
  // available server games so shows blank early
  return (
    <div css={bannerContainerStyle} className="banner-container">
      {/* Filter input */}

      <input
        type="text"
        css={bannerGameFilter}
        disabled={currentGame !== 'all'}
        className="filter-input"
        value={gameFilter}
        placeholder="Filter games"
        onChange={(e) => setGameFilter(e.target.value)} // Update filter state
      />

      <div id="banner-anchor" className="banner-images" css={displayGrid}>
        {filteredGamesList.length === 0
          ? noGames()
          : filteredGamesList.map((game) => {
              const active =
                currentServerGames?.includes(game.name_raw) ?? false
              const isCurrentGame = game.name_raw === currentGame
              const isAll = currentGame === 'all'

              const transform = getTransform(isCurrentGame, isAll)
              const currentStyling = getCurrentStyling(
                isCurrentGame,
                isAll,
                currentGame,
              )

              const overStyling = getOverStyling(active)
              const imageStyle = getImageStyle(active)

              if (active) {
                return (
                  <div
                    key={game.name_raw}
                    css={flexxy}
                    className={`banner-image-container ${game.name_raw}`}
                  >
                    <NavLink
                      css={{ color: 'inherit' }}
                      to={`/servers/${currentHost}/${gameName(game.name_raw, currentGame)}`}
                    >
                      <div
                        css={[
                          linkStyling,
                          currentStyling,
                          overStyling,
                          imageStyle,
                          { transform },
                        ]}
                      >
                        <picture css={overStyling}>
                          <PictureSet
                            imagePath={imagePath}
                            imageName={game.name_raw}
                          />
                          <img
                            alt={`${game.name_nice}-game`}
                            data-value={game.name_raw}
                            // onClick={handleGameChange}
                            css={[imageStyle, currentStyling]}
                            className="game-banner-images"
                          />
                        </picture>
                        <p>{game.name_nice}</p>
                      </div>
                    </NavLink>
                  </div>
                )
              }

              return null
            })}
      </div>
    </div>
  )
}

export default GameBanners
