import React, {
  useState,
  useEffect,
  useCallback,
  MouseEvent,
  ChangeEvent,
} from 'react'
import { useTranslation } from 'react-i18next'
import DedicatedTitles from '../titles/dedicated'
import DedicatedHostRow from '../host-row/dedicated'
import { monthYear } from '../month-year'
import Loading from '../loading'
import UseMetaTags from '../meta-tags'
import { useNavigate, useLocation } from 'react-router-dom'
import { DediServerDataType } from '../../types/dediServerDataType'
import sortByNumberDedi from '../helpers/sort-by-number-dedi'
import { SortEnum } from '../grid'

type DedicatedProps = {
  fetchInitialData: () => Promise<DataFetchType>
}

type DataFetchType = {
  serverData: DediServerDataType[]
  uniqueHosts: string[]
}

const Dedicated: React.FC<DedicatedProps> = ({ fetchInitialData }) => {
  const [sorts, setSorts] = useState({
    cpuSort: true,
    memorySort: false,
    minPriceSort: true,
    establishedSort: true,
    reviewsSort: true,
    hostSort: true,
    CGSHSort: false,
    ratingSort: true,
  })
  const [currentSort, setCurrentSort] = useState<SortEnum>(
    SortEnum.overall_rating,
  )
  const [aOrB] = useState(Math.random() > 0.49)
  const [serverData, setServerData] = useState<DediServerDataType[]>([])
  const [uniqueHosts, setUniqueHosts] = useState<string[]>([])
  const [currentCountry, setCurrentCountry] = useState<string>('all')
  const [currentLanguage, setCurrentLanguage] = useState<string>('en')
  const [currentHost, setCurrentHost] = useState<string>('all')
  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const validHost = (host: string) => uniqueHosts.includes(host)

  const fetchDedicated = useCallback(() => {
    setLoading(true)
    fetchInitialData().then((dataFetch: DataFetchType) => {
      setServerData(dataFetch.serverData)
      setUniqueHosts(dataFetch.uniqueHosts)
      setLoading(false)
    })
  }, [fetchInitialData])

  const handleHostChange:
    | MouseEvent<HTMLButtonElement>
    | ((e: ChangeEvent<HTMLDivElement>) => void) = useCallback(
    (e: MouseEvent<HTMLButtonElement> | ChangeEvent<HTMLDivElement>) => {
      const host = (e.currentTarget.dataset.value ||
        (e.target as HTMLInputElement).value) as string
      const pathCheck = `/dedicated-gaming-servers/${host}`
      if (location.pathname !== pathCheck) {
        navigate(pathCheck)
      }
      setCurrentHost(host)
    },
    [navigate, location.pathname],
  )

  useEffect(() => {
    const { pathname } = location
    const host = pathname.split('/')[2]
    if (!serverData.length) {
      fetchDedicated()
      const validHostValue = validHost(host) ? host : 'all'
      if (currentHost !== validHostValue) {
        navigate(`/dedicated-gaming-servers/${validHostValue}`)
        setCurrentHost(validHostValue)
      }
    }
  }, [fetchDedicated, location, serverData.length, currentHost, navigate])

  const sortByRating = () => {
    setServerData((prevData) =>
      sortByNumberDedi(prevData, sorts.ratingSort, 'overall_rating'),
    )
    setSorts((prevSorts) => ({
      ...prevSorts,
      ratingSort: !prevSorts.ratingSort,
    }))
    setCurrentSort(SortEnum.overall_rating)
  }

  const sortByHosts = () => {
    setServerData((prevData) =>
      prevData.sort((a, b) =>
        !sorts.hostSort
          ? a.server_name.localeCompare(b.server_name)
          : b.server_name.localeCompare(a.server_name),
      ),
    )
    setSorts((prevSorts) => ({
      ...prevSorts,
      hostSort: !prevSorts.hostSort,
    }))
    setCurrentSort(SortEnum.hosts)
  }

  const sortByEstablished = (): void => {
    setServerData((prevData: DediServerDataType[]) =>
      sortByNumberDedi(prevData, sorts.establishedSort, 'established'),
    )
    setSorts((prevSorts) => ({
      ...prevSorts,
      establishedSort: !prevSorts.establishedSort,
    }))

    setCurrentSort(SortEnum.established)
  }

  const sortByCGSH = () => {
    setServerData((prevData) =>
      prevData.sort((a: DediServerDataType, b: DediServerDataType) => {
        if (sorts.CGSHSort) {
          return (
            parseInt(a.overall_rating) * a.review_count -
            parseInt(b.overall_rating) * b.review_count
          )
        }
        return (
          parseInt(b.overall_rating) * b.review_count -
          parseInt(a.overall_rating) * a.review_count
        )
      }),
    )
    setSorts((prevSorts) => ({
      ...prevSorts,
      CGSHSort: !prevSorts.CGSHSort,
    }))
    setCurrentSort(SortEnum.cgsh)
  }

  const sortByReviews = () => {
    setServerData((prevData) =>
      sortByNumberDedi(prevData, sorts.reviewsSort, 'review_count'),
    )
    setSorts((prevSorts) => ({
      ...prevSorts,
      reviewsSort: !prevSorts.reviewsSort,
    }))
    setCurrentSort(SortEnum.review_count)
  }

  if (loading) {
    return <Loading />
  }

  const date = new Date()
  const isAllHosts = currentHost === 'all'
  const metaDediTitle = isAllHosts ? 'meta.dedi.all' : 'meta.dedi.title'
  const metaDediDescription = isAllHosts
    ? 'meta.dedi.all-description'
    : 'meta.dedi.description'

  return (
    <React.Fragment>
      <div css={{ minHeight: '90vh' }}>
        <UseMetaTags
          title={t(metaDediTitle, {
            dedi: currentHost,
            monthYear: monthYear(date),
          })}
          content={t(metaDediDescription, {
            dedi: currentHost,
            monthYear: monthYear(date),
          })}
          ogDescription={`Hosts offering dedicated game servers ${date}`}
          ogTitle={`Game server hosts offering dedicated game servers ${date}`}
          type="website"
          ogUrl="https://comparegamerserverhosting/coupons"
          ogImageUrl="https://comparegameserverhosting/images/ogImages/logos/normal/all.png"
        />

        <DedicatedTitles
          sorts={sorts}
          sortByReviews={sortByReviews}
          sortByRating={sortByRating}
          sortByCGSH={sortByCGSH}
          sortByEstablished={sortByEstablished}
          sortByHosts={sortByHosts}
          currentSort={currentSort}
        />

        {serverData.map((server) => (
          <DedicatedHostRow
            key={server.server_name}
            currentCountry={currentCountry}
            currentHost={currentHost}
            handleHostChange={handleHostChange}
            server={server}
            aOrB={aOrB}
            hostName={server.server_name}
          />
        ))}
      </div>
    </React.Fragment>
  )
}

export default Dedicated
