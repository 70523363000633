import { css } from "@emotion/react";

export const specsContainerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f0f4ff; /* Light background color */
  padding: 20px;
  border-radius: 12px;
  @media (max-width: 768px) {
    width: 100%; /* Full width for mobile */
    margin-bottom: 20px;
    flex-direction: column;
    padding: 10px;
  }
`;

export const localContainerStyle = css`
  width: 100%;
`;

export const recommendedBlockStyle = css`
  flex: 1;
  border: 1px solid #007bff; /* Blue border */
  background-color: #e6f2ff; /* Light blue background */
  border-radius: 8px;
  text-align: center;
  margin-right: 20px;
  padding: 10px;
  box-sizing: border-box;
  color: #003366; /* Darker text for contrast */
  
  @media (max-width: 768px) {
    margin-bottom: 20px;
    padding: 0;
    width: 100%; /* Full width for mobile */
  }
`;

export const minimumBlockStyle = css`
  flex: 1;
  border: 1px solid #28a745; /* Green border */
  background-color: #e9f7ef; /* Light green background */
  border-radius: 8px;
  text-align: center;
  margin-left: 20px;
  padding: 10px;
  box-sizing: border-box;
  color: #155724; /* Darker green text for contrast */

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 0;
    margin-right: 20px;
    width: 100%; /* Full width for mobile */
  }
`;

export const specDetailsStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: bold;
  align-items: center; /* Aligns items vertically */
  
  /* Adjusting spacing between the labels and descriptions */
  & > div {
    margin-right: 10px; /* Decrease this value to reduce space */
    flex: 1; /* Ensures both sides take equal space */
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start; /* Align text and description to the start on mobile */
  }
`;

export const noMobileStyle = css`
  display: inline-block;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const mobileOnlyStyle = css`
  display: none;

  @media (max-width: 768px) {
    display: inline-block;
    font-size: 1.5em; /* Adjust size for the icons */
    margin-right: 10px; /* Some space between icon and text */
  }
`;
