/** @jsxImportSource @emotion/react */
import React, { useEffect, ChangeEvent, MouseEvent, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from '../../app/hooks/typedHooks' // Import typed hooks
import {
  fetchData,
  setSortedServerData,
} from '../../app/slices/gameServerDataSlice' // Assuming fetchData is defined in your slice
import GameBanners from '../game-banners'
import sortTheData from './sort-the-data'

// custom components
import FlagBanner from '../flag-banner'
import PlatformBanner from '../platform-banner'
import HostRow, { ServerDataType } from '../host-row'
import Loading from '../loading'
import Titles from '../titles/game-server'
import UseMetaTags from '../meta-tags'
import ServerPage from '../server-page'
import VideoIframe from '../helpers/video-iframe'
import Introduction from '../introduction'
import AmazonLinks from '../amazon-links'
import ShowMoreHostButton from './show-more-host-button'

// Helpers
import normaliseName from '../helpers/normalise-game-name'
import { monthYear } from '../month-year'

// Styles
import {
  showAllGamesButtonStyle,
  instructionContainerStyle,
  dualInstruction,
  iframeVideo,
  instructionsStyle,
  instructionsStyleBanners,
  largerFlex,
} from './styles'
import useAnalytics from '../hooks/useAnalytics'
import {
  setCurrentGame,
  setCurrentHost,
  setCurrentCountry,
  setCurrentPlatform,
} from '../../app/slices/currentGameHostSlice'
import Featured from '../featured'

const defaultNumberOfServersShown = 10

export enum SortEnum {
  overall_rating = 'overall_rating',
  established = 'established',
  review_count = 'review_count',
  hosts = 'hosts',
  cgsh = 'cgsh',
}

type GridProps = {}

const Grid: React.FC<GridProps> = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const data = useAppSelector((state) => {
    return state.gameServerData
  })

  const {
    serverData = [],
    sortedServerData = [],
    uniqueHosts = [],
    uniqueGames = [],
    loading,
  } = data

  const { currentGame, currentHost, currentCountry, currentPlatform } =
    useAppSelector((state) => state.currentGameHost)

  const [sorts, setSorts] = useState({
    ratingSort: true,
    establishedSort: true,
    reviewsSort: true,
    hostSort: true,
    CGSHSort: false,
  })

  const validateGame = (game = 'all') => {
    return uniqueGames.includes(game) ? game : 'all'
  }

  const validateHost = (host = 'all') => {
    return uniqueHosts.includes(host) ? host : 'all'
  }

  const [showIntro, setShowIntro] = useState(true)
  const [showAllGames, setShowAllGames] = useState(false)
  const [showHostTotal, setShowHostTotal] = useState(
    defaultNumberOfServersShown,
  )
  const [currentSort, setCurrentSort] = useState<SortEnum>(
    SortEnum.overall_rating,
  )
  const [aOrB] = useState(Math.random() > 0.49)

  const { host, game } = useParams()

  useEffect(() => {
    if (!serverData.length) {
      console.info(
        'trying to fetch data from server because serverData is empty',
      )
      dispatch(fetchData())
    }
  }, [serverData, dispatch])

  useEffect(() => {
    // Decode the URL parameters
    const decodedHost = decodeURI(host || '')
    const decodedGame = decodeURI(game || '')

    const validatedHost = validateHost(decodedHost || 'all')
    const validatedGame = validateGame(decodedGame || 'all')

    if (currentHost !== validatedHost) {
      dispatch(setCurrentHost(validatedHost))
    }
    if (currentGame !== validatedGame) {
      dispatch(setCurrentGame(validatedGame))
    }
  }, [host, game, currentHost, currentGame, dispatch])

  useAnalytics({
    event: 'pageview',
    page: location.pathname,
    title: 'Host-Game',
  })

  const handleSortData = (sortBy: SortEnum) => {
    const { sortedData, updatedSorts } = sortTheData(sortBy, serverData, sorts)
    dispatch(setSortedServerData(sortedData))
    setSorts(updatedSorts)
    setCurrentSort(sortBy)
  }

  const sortByRating = () => {
    handleSortData(SortEnum.overall_rating)
  }

  const sortByEstablished = () => {
    handleSortData(SortEnum.established)
  }

  const sortByReviews = () => {
    handleSortData(SortEnum.review_count)
  }

  const sortByHosts = () => {
    handleSortData(SortEnum.hosts)
  }

  const sortByCGSH = () => {
    handleSortData(SortEnum.cgsh)
  }

  const handleCountryChange = (
    event: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>,
  ) => {
    const newCountry =
      event.currentTarget.dataset.country ||
      (event.currentTarget as HTMLInputElement).value ||
      'all'

    const country = newCountry === currentCountry ? 'all' : newCountry

    if (country !== currentCountry) {
      dispatch(setCurrentCountry(country))
    }
  }

  const handlePlatformChange = (
    event: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>,
  ) => {
    const newPlatform =
      event.currentTarget.dataset.platform ||
      (event.currentTarget as HTMLInputElement).value ||
      'all'

    const platform = newPlatform === currentPlatform ? 'all' : newPlatform

    if (platform !== currentPlatform) {
      dispatch(setCurrentPlatform(platform))
    }
  }

  const showAllHostsToggle = () => {
    setShowHostTotal((prev) =>
      prev === defaultNumberOfServersShown ? 17 : defaultNumberOfServersShown,
    )
  }

  const toggleShowIntro = () => {
    setShowIntro((prev) => !prev)
  }

  const showAllGamesToggle = () => {
    const newShowAllGames = currentHost === 'all' ? !showAllGames : true

    dispatch(setCurrentHost(newShowAllGames ? 'all' : currentHost))
    dispatch(setCurrentGame(newShowAllGames ? 'all' : currentGame))
    setShowAllGames(newShowAllGames)
  }

  const isAllHosts = currentHost === 'all'
  const isAllGames = currentGame === 'all'

  const formattedGameName = normaliseName(currentGame as string)
  const allGamesSelected = currentGame === 'all'

  let showGameDesc = allGamesSelected ? { display: 'none' } : {}

  const metaTitle =
    isAllGames && isAllHosts ? 'meta.game.all' : 'meta.game.title'

  const metaDescription =
    isAllGames && isAllHosts
      ? 'meta.game.all-description'
      : 'meta.game.description'

  if (loading) {
    return <Loading />
  }

  // change description to be game and host specific for ogdescription
  let ogDescription = ''
  switch (true) {
    case isAllGames && !isAllHosts:
      ogDescription = `Compare the best game server hosting providers for ${currentHost}. Filter by location, platform and more.`
      break
    case !isAllGames && isAllHosts:
      ogDescription = `Compare the best game server hosting providers for ${currentGame}. Filter by location, platform and more.`
      break
    case !isAllGames && !isAllHosts:
      ogDescription = `Buy a ${currentGame} game server from ${currentHost}. Filter by location, platform and more.`
    case isAllGames && isAllHosts:
    default:
      ogDescription =
        'Compare the best game server hosting providers for Ark Survival Evolved, Rust, 7 Days to Die, Minecraft and more. Filter by location, platform and more.'
  }

  const hasThisGameFeatured = (server: ServerDataType) =>
    server.games.some(
      (game) => game.featured === 'yes' && game.name_raw === currentGame,
    )

  const featuredHosts = serverData
    .filter((server) => {
      const gameFeatured = hasThisGameFeatured(server)

      return server.featured === 'yes' || gameFeatured
    })
    .map((server, index) => {
      const gameFeatured = hasThisGameFeatured(server)
      currentGame !== 'all' &&
        server.games.some(
          (game) => game.featured === 'yes' && game.name_raw === currentGame,
        )

      const shouldShowFeatured: boolean =
        (server.featured === 'yes' &&
          currentGame === 'all' &&
          currentHost === 'all') ||
        ((gameFeatured as boolean) &&
          currentHost == 'all' &&
          currentGame !== 'all')

      return (
        <Featured
          shouldShow={shouldShowFeatured}
          key={`featured-${server.server_name}`}
        >
          <HostRow
            key={`featured-${server.server_name}`}
            limitNumbers={index > showHostTotal}
            // handleHostChange={handleHostChange}
            server={server}
            aOrB={aOrB}
          />
        </Featured>
      )
    })

  return (
    <React.Fragment>
      <div css={{ minHeight: '90vh' }}>
        <UseMetaTags
          title={t(metaTitle, {
            game: formattedGameName,
            monthYear: monthYear(new Date()),
          })}
          type="website"
          content={t(metaDescription, {
            game: game,
            gamesTotal: uniqueGames.length,
            monthYear: monthYear(new Date()),
          })}
          // TODO fix og description for specific games
          ogDescription={ogDescription}
          ogTitle={`${currentGame} game servers ${monthYear(new Date())}`}
          ogImageUrl={`https://comparegameserverhosting.com/images/ogImages/bannerImages/normal/${currentGame}.png`}
          ogUrl={`https://comparegameserverhosting.com/servers/${currentHost}/${currentGame}`}
        />
        <AmazonLinks />
        <div
          className="instruction-container"
          css={[instructionContainerStyle]}
        >
          <div
            className="instruction-component"
            css={[instructionsStyleBanners, { minHeight: '17em' }]}
          >
            <GameBanners />
          </div>
          <div
            className="dual-instruction"
            css={[dualInstruction, { minHeight: '7em' }]}
          >
            <div
              className="instruction-component"
              css={[instructionsStyle, largerFlex]}
            >
              <FlagBanner
                serverData={serverData}
                handleCountryChange={handleCountryChange}
              />
              <PlatformBanner
                currentPlatform={currentPlatform}
                handlePlatformChange={handlePlatformChange}
              />
            </div>
            <div css={iframeVideo} className="iframe-video">
              <VideoIframe game={currentGame} />
            </div>
          </div>
          <Titles
            sorts={sorts}
            sortByReviews={sortByReviews}
            sortByRating={sortByRating}
            sortByCGSH={sortByCGSH}
            sortByEstablished={sortByEstablished}
            sortByHosts={sortByHosts}
            currentSort={currentSort}
          />
        </div>
        {featuredHosts}
        {serverData.map((server, index) => {
          return (
            <HostRow
              key={server.server_name}
              limitNumbers={index > showHostTotal}
              // handleHostChange={handleHostChange}
              server={server}
              aOrB={aOrB}
            />
          )
        })}
        <div
          css={{
            textAlign: 'center',
            marginTop: '1em',
          }}
        >
          <ShowMoreHostButton
            currentHost={currentHost}
            showAllHostsToggle={showAllHostsToggle}
            defaultNumberOfServersShown={defaultNumberOfServersShown}
            showHostTotal={showHostTotal}
          />
        </div>
        <Introduction toggleShowIntro={toggleShowIntro} show={showIntro} />
        {currentHost !== 'all' ? (
          <ServerPage
            aOrB={aOrB}
            // handleHostChange={handleHostChange}
            sortByReviews={sortByReviews}
            serverData={serverData.find(
              (server: ServerDataType) => server.server_name === currentHost,
            )}
          />
        ) : null}
        <section
          css={[showGameDesc, { marginBottom: '1em', marginTop: '1em' }]}
        ></section>
      </div>
    </React.Fragment>
  )
}

export default Grid
