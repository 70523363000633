/** @jsxImportSource @emotion/react */
import React from 'react'
import { NavLink } from 'react-router-dom'
import UseMetaTags from '../meta-tags'
import { monthYear } from '../month-year'
import PictureSet from '../helpers/picture-set'
// this needs to be updated from dashboard to get unique games.
// add fetch to get unique games from the server on the componenet and add to the state.
// import uniqueGames from './uniqueGames'
import {
  homePageStyle,
  gameList,
  imageGameBanner,
  gameImage,
  startLinkStyle,
  imageContainer,
} from './styles'

const uniqueGames = [
  'minecraft',
  'rust',
  'ark-survival-evolved',
  'valheim',
  '7-days-to-die',
  'dayz',
  'arma-3',
  'conan-exiles',
  'terraria',
  'garrys-mod',
  'space-engineers',
  'factorio',
  'palworld',
  'enshrouded',
  'soulmask',
  'satisfactory',
]

const recentlyAddedGamesList = [
  'palworld',
  'enshrouded',
  'soulmask',
  'satisfactory',
]

const Home = () => {
  const imagePath = 'images/bannerImages'
  const date = monthYear(new Date())
  return (
    <React.Fragment>
      <UseMetaTags
        content={`Game server hosts offering dedicated game servers ${date}`}
        title={`Search Game Server hosts offering games ${date}`}
        ogDescription={`Hosts offering dedciated game servers ${date}`}
        ogTitle={`Game server hosts offering dedicated game servers ${date}`}
        type="website"
        ogUrl="https://comparegamerserverhosting"
        ogImageUrl="https://comparegameserverhosting/images/ogImages/logoso/normal/all.png"
      />
      <div css={homePageStyle}>
        <h1>Welcome to HOSTFILTER</h1>
        <p>
          Pick an online game to see a list of hosts offering dedicated game
          servers for that game.
        </p>
        <p>Recently added games</p>
        <ul css={gameList}>
          {recentlyAddedGamesList.map((game: string) => {
            return (
              <li key={game}>
                <a href={`/servers/all/${game}`}>
                  <picture css={imageGameBanner}>
                    <PictureSet imagePath={imagePath} imageName={game} />
                    <img
                      loading="lazy"
                      // src={`/${imagePath}/png/small/${game}.png`}
                      alt={game}
                      css={gameImage}
                    />
                  </picture>
                </a>
              </li>
            )
          })}
        </ul>
        <NavLink css={startLinkStyle} to="/servers/all/all">
          All Games
        </NavLink>
        <div css={imageContainer} className="image-container">
          {uniqueGames.slice(0, 12).map((game) => {
            return (
              <a key={game} href={`/servers/all/${game}`}>
                <picture css={imageGameBanner}>
                  <PictureSet imagePath={imagePath} imageName={game} />
                  <img
                    loading="lazy"
                    // src={`/${imagePath}/png/small/${game}.png`}
                    alt={game}
                    css={gameImage}
                  />
                </picture>
              </a>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home
